import type { Menu, MenuItem } from "@/cms/schema";
import { PropsWithChildren } from "react";
import { AnimatedLink, AnimatedUnderline } from "./animatedLink";

interface Props {
  menu: Menu;
  onShowCookieSettings: () => void;
  serverComponents: {
    socials: React.ReactNode;
    newsletter: React.ReactNode;
  };
}

export default function Footer({
  menu,
  onShowCookieSettings,
  serverComponents,
}: Props) {
  return (
    <footer className="border-t-2 border-gray-800 p-6">
      <div className="mb-8 text-gray-400">{serverComponents.socials}</div>

      <Menu menuItems={menu.items}>
        <li>
          <AnimatedUnderline underlineInitial={false}>
            <button onClick={onShowCookieSettings}>Cookie Settings</button>
          </AnimatedUnderline>
        </li>
      </Menu>
      <div className="mt-8 w-full text-center text-sm text-gray-300">
        © 2025 | Students and Management Team of CDTM
      </div>
    </footer>
  );
}

interface MenuProps {
  menuItems: MenuItem[];
}

function Menu({ children, menuItems }: PropsWithChildren<MenuProps>) {
  const linkItems = menuItems.map(({ id, label, url }) => (
    <li key={id}>
      <AnimatedLink href={url!} text={label} underlineInitial={false} />
    </li>
  ));

  return (
    <ul className="flex w-full justify-center gap-3 text-center text-gray-300">
      {linkItems}
      {children}
    </ul>
  );
}
